import { useTranslation } from "react-i18next";
import "./table.css";
import React, { useEffect, useState } from "react";
import StatusPill from "../status-pill/status-pill";
import BackorderStatus from "../backorder-status/backorder-status";

const Table = ({
    headers,
    rows,
    keys,
    rowClickFunction,
    collapse,
    setCollapse,
}) => {
    const [expendedRow, setExpendedRow] = useState({});
    const { t } = useTranslation();
    useEffect(() => {
        if (collapse) {
            setExpendedRow(new Array(rows.length).fill(false));
            setCollapse(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, collapse]);

    const getTableHeaders = () => {
        const ths = [];
        headers.forEach((theader) => {
            ths.push(<th key={theader.key}>{t(theader.label)}</th>);
        });
        return ths;
    };

    const expendRow = (index) => {
        setExpendedRow(() => ({
            ...expendedRow,
            [index]: !expendedRow[index],
        }));
    };

    return (
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr>{getTableHeaders()}</tr>
                </thead>
                <tbody>
                    {rows?.map((row, index) => (
                        <React.Fragment key={index}>
                            <tr
                                className={
                                    rowClickFunction
                                        ? "clickable"
                                        : expendedRow[index]
                                        ? "selected-main"
                                        : ""
                                }
                                key={index}
                                onClick={() => {
                                    if (rowClickFunction) {
                                        rowClickFunction(row);
                                    }
                                }}
                            >
                                {keys?.map((key) => {
                                    if (key === "currentStatus") {
                                        return (
                                            <td
                                                className={
                                                    row[key].value ===
                                                    "collapse"
                                                        ? "clickable dark-blue"
                                                        : ""
                                                }
                                                key={key + index}
                                                onClick={() => {
                                                    if (
                                                        row[key].value !==
                                                        "ordered"
                                                    ) {
                                                        expendRow(index);
                                                    }
                                                }}
                                            >
                                                <StatusPill
                                                    status={row[key].value}
                                                    collapseValue={
                                                        expendedRow[index]
                                                    }
                                                />
                                            </td>
                                        );
                                    } else if (key === "backOrdered") {
                                        return (
                                            <td key={key + index}>
                                                <BackorderStatus
                                                    boValue={row[key].value}
                                                    boComment={
                                                        row.backOrderComment
                                                    }
                                                />
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td
                                                className={
                                                    row[key].clickFunction
                                                        ? `clickable underline ${key}`
                                                        : ""
                                                }
                                                key={key + index}
                                                onClick={row[key].clickFunction}
                                            >
                                                {row[key].value}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                            {expendedRow[index] &&
                                row.subRows.map((subRow, i) => (
                                    <tr
                                        className={
                                            expendedRow[index] ? "selected" : ""
                                        }
                                        key={"subRow" + index + i}
                                    >
                                        {keys.map((key) => {
                                            if (key === "currentStatus") {
                                                return (
                                                    <td
                                                        className={
                                                            subRow[key]
                                                                .value ===
                                                            "collapse"
                                                                ? "clickable underline"
                                                                : ""
                                                        }
                                                        key={key + index}
                                                        onClick={() => {
                                                            if (
                                                                subRow[key]
                                                                    .value ===
                                                                "collapse"
                                                            ) {
                                                                expendRow(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <StatusPill
                                                            status={
                                                                subRow[key]
                                                                    .value
                                                            }
                                                        />
                                                    </td>
                                                );
                                            } else if (key === "backOrdered") {
                                                return (
                                                    <td key={key + index}>
                                                        <BackorderStatus
                                                            boValue={
                                                                subRow[key]
                                                                    .value
                                                            }
                                                            boComment={
                                                                subRow.backOrderComment
                                                            }
                                                        />
                                                    </td>
                                                );
                                            } else if (key === "lineNumber") {
                                                return (
                                                    <td
                                                        className={
                                                            subRow[key]
                                                                .clickFunction
                                                                ? "clickable underline"
                                                                : ""
                                                        }
                                                        key={key}
                                                        onClick={
                                                            subRow[key]
                                                                .clickFunction
                                                        }
                                                    >
                                                        
                                                    </td>
                                                )
                                            }else {
                                                return (
                                                    <td
                                                        className={
                                                            subRow[key]
                                                                .clickFunction
                                                                ? `clickable underline ${key}`
                                                                : ""
                                                        }
                                                        key={key}
                                                        onClick={
                                                            subRow[key]
                                                                .clickFunction
                                                        }
                                                    >
                                                        {subRow[key].value}
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
